<template>
  <div>
    <!-- SECTION - All Curriculum -->
    <div>
      <!-- Banner -->
      <!-- <VueSlickCarousel :arrows="true" :dots="true"> -->
        <div>
          <img
            class="bannerPic"
            src="../assets/images/spongaBanner.png"
            alt=""
          />
        </div>
      <!-- </VueSlickCarousel> -->
    </div>
    <div class="container" style="margin-top: -40px">
      <div class="filter">
        <div class="row align-items-center">
          <div class="col-12 col-lg-3">
            <div class="d-flex align-items-center">
              <!-- <div class="position-relative w-100" style="margin-right: 10px">
                <input
                  type="text"
                  placeholder="search"
                  class="w-100"
                  v-model="searchTerm"
                  v-debounce:500ms="queryCurriculum"
                />
                <img
                  class="position-absolute"
                  style="right: 10px; top: 50%; transform: translateY(-50%)"
                  src="../assets/images/search.svg"
                  alt=""
                />
              </div>
              <img
                class="d-block d-lg-none"
                style="cursor: pointer"
                src="../assets/images/filter.svg"
                alt=""
                @click="toggleFilter"
              />
            </div>
          </div> -->
              <!-- <div
            class="col-12 col-lg-9 checkbox-container"
            :class="{ 'show-filter': showFilter }"
          >
            <div class="d-flex flex-wrap flex-column flex-lg-row mt-3 mt-lg-0">
              <CustomCheckbox
                class="mr-3"
                label="หลักสูตรทั้งหมด"
                :checked="filterArr.length === filterList.length"
                @toggleCheck="toggleCheckbox"
              />
              <CustomCheckbox
                class="mr-3"
                v-for="filter in filterList"
                :key="filter.id"
                :label="filter.title"
                :checked="isInFilter(filter.title)"
                @toggleCheck="toggleCheckbox"
              /> -->
              <!-- <CustomCheckbox
                class="mr-3"
                label="หลักสูตรบัญชี"
                :checked="accountingCourse"
                @toggleCheck="toggleCheckbox"
              />
              <CustomCheckbox
                label="หลักสูตรเฉพาะกลุ่ม"
                :checked="organizationCourse"
                @toggleCheck="toggleCheckbox"
              /> -->
            </div>
          </div>
        </div>
      </div>
      <h4 class="font-weight-bold mr-3 mb-2">วิดีโอทั้งหมด</h4>

      <div v-if="curriculumList.length > 0" class="row">
        <div
          v-for="curriculum in curriculumList"
          :key="curriculum.id"
          class="col-12 col-md-4 my-3"
        >
          <CourseCard :course="curriculum" type="Temporary" />
        </div>
      </div>
      <div v-else>
        <h3 class="color-text text-center">ไม่มีคอร์สที่จะแสดง</h3>
      </div>
      <p
        v-if="curriculumTotal > curriculumList.length"
        class="see-more color-secondary text-big font-weight-bold"
        @click="loadMoreCourse"
      >
        ดูเพิ่มเติม
      </p>
    </div>
    <hr
      style="
        border-top: 3px solid rgb(136, 136, 255);
        margin: 50px 30%;
        padding: 30px;
      "
    />
  </div>
</template>

<script>
import CourseCard from "../components/CourseCard.vue";
import CustomCheckbox from "../components/CustomCheckbox.vue";
import axios from "../api/faAPI";
import Qs from "qs";
import baseUrl from "../util/backend";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  components: {
    CourseCard,
    CustomCheckbox,
    VueSlickCarousel,
  },
  data() {
    return {
      permissionID: null,
      bannerList: null,
      // ----- for filter ---- //
      filterList: [
        { id: 1, title: "หลักสูตรบัญชี" },
        { id: 2, title: "หลักสูตรเฉพาะกลุ่ม" },
      ],
      filterArr: [],
      searchTerm: "",
      showFilter: false,
      // <<-- for filter -->> //
      // allCurriculumList: [
      //   {
      //     course: {
      //       title: "การลงทะเบียนผู้ใช้ใหม่",
      //       sub_title:
      //         "ซอฟต์แวร์สำหรับตรวจนับสินทรัพย์ที่จะช่วยเพิ่มความสะดวกในการตรวจนับสินทรัพย์ อีกทั้งยังมีความปลอดภัยในการเก็บข้อมูลสูงและสามารถดูข้อมูลสรุปรายงานการตรวจนับสินทรัพย์ได้แบบเรีบลไทม์สามารถใช้งานได้ทั้งบน Website และ Mobile Application (iOS, Android)",
      //       course_id: 1,
      //       level: "Basic",
      //       cover_img:
      //         "https://is4-ssl.mzstatic.com/image/thumb/PurpleSource116/v4/6e/03/cf/6e03cf5a-1ee5-b854-2d96-dbf03d15af1d/bc88f498-30b6-4d06-9a1f-94e643329ad7_Frame_11167.jpg/300x0w.jpg",
      //       couresTime: "1 ชั่วโมง",
      //       link: "65c8cd179c98d6b1f8f097613ff27ad2",
      //     },
      //   },
      //   {
      //     course: {
      //       title:
      //         "ขั้นตอนการใช้งานVERSCANเบื้องต้น 1 ฉบับเริ่มต้นมือใหม่ดูแล้วทำได้เลย",
      //       sub_title:
      //         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      //       course_id: 2,
      //       level: "Basic",
      //       cover_img:
      //         "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVAuFAl6w9LSfXSp6ixMGfufieDyh2czQIy8L5se10yoyZYdyESpKOMq-W3NwZR7DqKc8&usqp=CAU",
      //       couresTime: "2 ชั่วโมง",
      //       link: "a738deb84a442328c48a1cdee45c302d",
      //     },
      //   },
      //   {
      //     course: {
      //       title: "ขั้นตอนการใช้งานVERSCANเบื้องต้น 2",
      //       sub_title:
      //         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      //       course_id: 3,
      //       level: "Basic",
      //       cover_img:
      //         "https://media.licdn.com/dms/image/C5633AQGX6Wst8NAm8w/productpage-image_1128_635/0/1642354212169/houseofdevtech_verscan_a_new_way_to_verify_the_fixed_assets__image?e=2147483647&v=beta&t=GjM5X-UiOv-bURopgzE4pWfoAm8NA1GDbY1RcwVgeT4",
      //       couresTime: "1 ชั่วโมง",
      //       link: "ed6746bdf48b0e24977e1b08586f3a28",
      //     },
      //   },
      //   {
      //     course: {
      //       title: "ขั้นตอนการใช้งานVERSCANเบื้องต้น 3",
      //       sub_title:
      //         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      //       course_id: 4,
      //       level: "Basic",
      //       cover_img:
      //         "https://media.licdn.com/dms/image/C5633AQG7uDFIGwQqwQ/productpage-image_1128_635/0/1642354194458/houseofdevtech_verscan_a_new_way_to_verify_the_fixed_assets__image?e=2147483647&v=beta&t=0vuTfYhTppj-Z7TCiGfZSCovsEN7Cy-4-RnpLtkaOTY",
      //       couresTime: "2 ชั่วโมง",
      //       link: "65c8cd179c98d6b1f8f097613ff27ad2",
      //     },
      //   },
      //   {
      //     course: {
      //       title: "ขั้นตอนการใช้งานVERSCANเบื้องต้น 4",
      //       sub_title:
      //         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      //       course_id: 5,
      //       level: "Basic",
      //       cover_img:
      //         "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuVhErxLS8DF6LUKBqcDkOgY9-b26S3vcJXqOyZFHDYVXpZdmg6l6t_K7KZDzckTevC34&usqp=CAU",
      //       couresTime: "3 ชั่วโมง",
      //       link: "a738deb84a442328c48a1cdee45c302d",
      //     },
      //   },
      //   {
      //     course: {
      //       title: "ขั้นตอนการใช้งานVERSCANเบื้องต้น 5",
      //       sub_title:
      //         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      //       course_id: 6,
      //       level: "Basic",
      //       cover_img:
      //         "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfBCb6BEPXRaafTxnIJEq0K0igBJrc5LXMow&usqp=CAU",
      //       couresTime: "1 ชั่วโมง",
      //       link: "ed6746bdf48b0e24977e1b08586f3a28",
      //     },
      //   },
      // ],
      allCurriculumList: [],
      curriculumList: [],
      page: 1,
      curriculumTotal: 0,
      bannerType: "image",
      playerOptions: {
        autoplay: true,
        fluid: true,
        aspectRatio: "16:9",
        autoplay: true,
        // poster:
        //   "https://qoder.byteark.com/images/video-frames/1/GU/cg/1GUcgd3XwsmD-large.jpg",
        sources: {
          src: "",
          type: "application/x-mpegURL",
          // Optional
          title: "LIVE",
          videoId: "RI2PimuHxDXw",
          // poster:
          //   "https://qoder.byteark.com/images/video-frames/1/GU/cg/1GUcgd3XwsmD-large.jpg",
        },
      },
    };
  },
  //test token
  async mounted() {
    if (localStorage.getItem("right")) {
      await this.$store.dispatch("decryptToken", localStorage.getItem("right"));
    }
    this.permissionID = await this.$store.getters.getPermissionID;
    this.queryCurriculum();
  },
  watch: {
    filterArr() {
      this.page = 1;
      this.curriculumList = [];
      if (this.filterArr.length > 0) {
        console.log("have filter");
        this.allCourse = false;
      } else {
        console.log("no filter");
        this.allCourse = true;
      }
      this.queryCurriculum();
    },
    searchTerm() {
      this.page = 1;
    },
  },
  methods: {
    isInFilter(name) {
      if (this.filterArr.includes(name)) return true;
      else return false;
    },
    goToBannerLink(link) {
      window.open(`${link}`, "_blank");
    },
    toggleCheckbox(value, name) {
      console.log(value, name);
      if (name !== "หลักสูตรทั้งหมด") {
        if (value) {
          this.filterArr.push(name);
        } else {
          this.filterArr = this.filterArr.filter((x) => x !== name);
        }
      }
      // if (name === "หลักสูตรเฉพาะกลุ่ม") {
      //   this.organizationCourse = value;
      //   if (!value) this.allCourse = false
      // }
      // if (name === "หลักสูตรบัญชี") {
      //   this.accountingCourse = value;
      //   if (!value) this.allCourse = false
      // }
      if (name === "หลักสูตรทั้งหมด") {
        if (value) {
          this.filterArr = this.filterList.map((x) => x.title);
        } else {
          this.filterArr = [];
        }
      }
      console.log(this.filterArr);
    },
    queryCurriculum() {
      if (this.permissionID) {
        this.curriculumList = this.allCurriculumList
          .filter((course) =>
            course.course.title
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase())
          )
          .filter(
            (course) =>
              course.course.permission.includes(parseInt(this.permissionID)) ||
              course.course.permission.includes(0)
          );
      } else {
        this.curriculumList = this.allCurriculumList
          .filter((course) =>
            course.course.title
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase())
          )
          .filter((course) => course.course.permission.includes(0));
      }
      // console.log(this.curriculumList);
    },

    // console.log(this.curriculumList);
    // this.curriculumList.forEach((test) => {
    //   test.course.permission.forEach((right) => {
    //     if (right == this.permissionID) {
    //       console.log('see')
    //     }
    //   });
    // });

    // queryCurriculum() {
    //   this.page = 1;
    //   this.curriculumList = [];
    //   this.loadCourse();
    // },
    async loadCourse() {
      if (this.searchTerm || this.filterArr.length > 0) {
        try {
          const res = await axios.get(`${baseUrl}/curriculumFilter`, {
            paramsSerializer: (params) =>
              Qs.stringify(params, { arrayFormat: "repeat" }),
            params: {
              page: this.page,
              limit: 9,
              name: this.searchTerm ? this.searchTerm : undefined,
              category: this.filterArr,
              user_id: this.$store.getters.getUser
                ? this.$store.getters.getUser.user_id
                : undefined,
            },
          });
          console.log(res.data);
          this.curriculumList = [...this.curriculumList, ...res.data.data];
          this.curriculumTotal = res.data.count;
        } catch (err) {
          if (err.response) {
            alert(err.response.data.error || "error");
          } else {
            alert(err);
          }
        }
      } else {
        try {
          const res = await axios.get(`${baseUrl}/getAllCurriculum`, {
            params: {
              page: this.page,
              limit: 9,
              user_id: this.$store.getters.getUser
                ? this.$store.getters.getUser.user_id
                : undefined,
            },
          });
          console.log(res.data);
          this.curriculumList = [...this.curriculumList, ...res.data.data];
          this.curriculumTotal = res.data.count;
        } catch (err) {
          if (err.response) {
            alert(err.response.data.error || "error");
          } else {
            alert(err);
          }
        }
      }
    },
    async loadMoreCourse() {
      this.page += 1;
      this.loadCourse();
    },
    async loadFilterList() {
      let filterList = [];
      const res = await axios.get(`${baseUrl}/getAllCategoryCurriculum`);
      filterList = res.data.data;
      filterList = filterList.filter((x) => x.type === "หลักสูตรหลัก");
      this.filterList = filterList;
      console.log("filter", filterList);
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    async loadBanner() {
      try {
        const res = await axios.get(`${baseUrl}/getBanner`);
        this.bannerType = res.data.type;
        if (res.data.type === "image") {
          this.bannerList = res.data.data;
        }
        if (res.data.type === "live") {
          this.playerOptions.sources.src = res.data.data.url;
        }
      } catch (err) {
        if (err.response) {
          alert(err.response.data.error || "error");
        } else {
          alert(err);
        }
      }
    },
  },
  created() {
    this.filterArr = this.filterList.map((x) => x.title);
    this.allCurriculumList = this.$store.getters.getCourseData;
    // this.loadCourse();
    // this.loadBanner();
    // this.loadFilterList();
  },
};
</script>

<style scoped>
a,
a:hover {
  color: inherit;
  text-decoration: none;
}

.thumbnail-container {
  cursor: pointer;
  position: relative;
  width: 100%;
  padding-top: 75%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.thumbnail-container img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
}

.banner {
  margin-bottom: 50px;
}

.banner >>> .VueCarousel-dot-container[style] {
  margin: 0 !important;
}

.banner >>> .VueCarousel-dot.VueCarousel-dot--active[style] {
  background-color: #07c5f8 !important;
  outline: none;
}

.banner >>> .VueCarousel-dot {
  background-color: #c4c4c4 !important;
  outline: none;
  margin: 0 !important;
}

.filter {
  margin: 50px 0;
}

.filter input[type="text"] {
  outline: none;
  border: 1px solid #798da5;
  padding: 10px;
}

.filter input[type="checkbox"] {
  width: 25px;
  height: 25px;
  vertical-align: middle;
}

.checkbox-container {
  height: 0;
  overflow: hidden;
  transition: all 1s;
}

.show-filter {
  height: auto;
}

.see-more {
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
  margin: 10px;
}

@media only screen and (min-width: 768px) {
  /* .slide-container {
    max-width: 700px;
  }
  .banner >>> .VueCarousel-slide {
    padding: 30px 0;
  } */
  .banner >>> .VueCarousel-wrapper {
    background: linear-gradient(90deg, #bfeaf5 0%, #ecf1ff 75.52%);
  }

  .thumbnail-container {
    cursor: pointer;
    position: relative;
    width: 100%;
    padding-top: 33.33%;
    /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  }

  .bannerPic {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .checkbox-container {
    height: auto;
    overflow: hidden;
  }

  .download-sheet {
    display: none;
  }
}

.bannerPic {
  width: 100%;
  object-fit: cover;
}
</style>
